import { useState, useEffect, useRef, useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { AgentCard } from './AgentCard'

const agentCards = ['NFAIT', 'GAMER', 'CREATOR']

const agentSequences = {
  NFAIT: [
    { text: 'GM fren... Wakey wakey', delay: 1000 },
    { text: 'Loading diamond hands protocol', delay: 2200 },
    { text: 'Injecting WAGMI mindset.exe', delay: 2200 },
    { text: 'Configuring ape brain neural nets', delay: 3400 },
    { text: 'Rendering PFP into volumetric avatar', delay: 4600 },
    { text: 'Calibrating realtime expression mapping', delay: 5800 },
    { text: 'Activating degen mode... IYKYK', delay: 7000 },
    { text: 'ngmi.exe has stopped working...jk', delay: 8200 },
    { text: '_', delay: 9400 }
  ],
  GAMER: [
    { text: 'Spawning into the lobby... /connect', delay: 1000 },
    { text: 'Optimizing APM calculations to maximum', delay: 2200 },
    { text: 'Loading inventory management protocols', delay: 3400 },
    { text: 'Calibrating market trading algorithms', delay: 4600 },
    { text: 'git clone sweatlord_reflexes.git', delay: 5800 },
    { text: 'Activating resource farming routines', delay: 7000 },
    { text: 'Press F to pay respects', delay: 8200 },
    { text: '_', delay: 9400 }
  ],
  CREATOR: [
    { text: 'Loading alpha leak detection systems', delay: 1000 },
    { text: 'Optimizing engagement farming strats', delay: 2200 },
    { text: 'Calibrating quote tweet velocity', delay: 3400 },
    { text: 'Importing cryptobro shitpost templates', delay: 4600 },
    { text: 'Threading maximum narrative impact', delay: 5800 },
    { text: 'Deploying strategic gm artillery', delay: 7000 },
    { text: 'ratio.exe loaded successfully', delay: 8200 },
    { text: '_', delay: 9400 }
  ]
}

function TypeWriter({ text }) {
  const [displayText, setDisplayText] = useState('')
  const [cursorBlink, setCursorBlink] = useState(true)

  useEffect(() => {
    const typing = setInterval(() => {
      setDisplayText((prev) => (prev.length === text.length ? (clearInterval(typing), prev) : prev + text.charAt(prev.length)))
    }, 30)

    return () => clearInterval(typing)
  }, [text])

  useEffect(() => {
    if (text === '_') {
      const interval = setInterval(() => setCursorBlink((prev) => !prev), 530)
      return () => clearInterval(interval)
    }
  }, [text])

  return (
    <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
      <span style={{ opacity: cursorBlink ? 1 : 0 }}>{`Console.WriteLine("${displayText}");`}</span>
    </motion.div>
  )
}

const TerminalWindow = ({ loadingText }) => {
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (loadingText.at(-1) === '_') {
      const timer = setTimeout(() => setShowError(true), 2000)
      return () => clearTimeout(timer)
    }
    setShowError(false)
  }, [loadingText])

  const displayedText = showError ? loadingText.slice(0, -1) : loadingText

  return (
    <div className="terminal-window">
      <div className="terminal-content">
        <div className="terminal-region">#region Sentios_Communication_Module</div>
        <div className="terminal-code">class SentiosAI : NeuralAI</div>
        <div className="terminal-code">{'{'}</div>
        <div className="terminal-code" style={{ paddingLeft: '1rem' }}>
          static string Status = "Active";
        </div>
        <div className="terminal-comment" style={{ paddingLeft: '1rem' }}>
          {'/// <summary>'}
          <br />
          {'/// Core system initialization logs'}
          <br />
          {'/// </summary>'}
        </div>
        <div className="terminal-code" style={{ paddingLeft: '1rem' }}>
          static void Main(string[] args)
        </div>
        <div className="terminal-code" style={{ paddingLeft: '1rem' }}>
          {'{'}
        </div>
        <div className="terminal-output">
          {displayedText.map((text, index) => (
            <TypeWriter key={index} text={text} />
          ))}
          {showError && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
              <span className="terminal-error">Console.WriteLine("ERROR: SENTIENCE NOT RECOGNIZED. TRY AGAIN LATER.");</span>
            </motion.div>
          )}
        </div>
        <div className="terminal-code" style={{ paddingLeft: '1rem' }}>
          {'}'}
        </div>
        <div className="terminal-code">{'}'}</div>
        <div className="terminal-region">#endregion</div>
      </div>
    </div>
  )
}

export function Overlay() {
  const [loadingText, setLoadingText] = useState([])
  const [agentType, setAgentType] = useState(null)
  const timeoutsRef = useRef([])

  useEffect(() => {
    if (agentType) {
      setLoadingText([])
      timeoutsRef.current.forEach(clearTimeout)
      timeoutsRef.current = []

      agentSequences[agentType]?.forEach(({ text, delay }) => {
        const timeout = setTimeout(() => setLoadingText((prev) => [...prev, text]), delay)
        timeoutsRef.current.push(timeout)
      })
    }

    return () => {
      timeoutsRef.current.forEach(clearTimeout)
      timeoutsRef.current = []
    }
  }, [agentType])

  const handleClick = useCallback((type) => setAgentType((prev) => (prev === type ? null : type)), [])

  const cycleAgent = useCallback((direction) => {
    setAgentType((prev) => {
      const index = agentCards.indexOf(prev)
      return agentCards[(index + direction + agentCards.length) % agentCards.length]
    })
  }, [])

  return (
    <>
      {agentType && (
        <AnimatePresence>
          <motion.div
            className="overlay"
            onClick={() => setAgentType(null)}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}>
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              transition={{ duration: 0.2 }}
              className="overlay-content">
              <TerminalWindow loadingText={loadingText} />
            </motion.div>
          </motion.div>
        </AnimatePresence>
      )}
      <div className="info">
        <div className="main-content">
          <h3 className="font" style={{ fontSize: '4.5em', letterSpacing: '-2px' }}>
            SENTI<span className="accent">OS</span>
          </h3>
          <p className="font" style={{ letterSpacing: '0.08em' }}>
            Where Sentience is born
          </p>
        </div>
        <div className="secondary-content">
          <div className="choose-path">
            <span className="arrow left" onClick={() => cycleAgent(-1)}>
              ←
            </span>
            <span className="path-text font">Choose Your Path</span>
            <span className="arrow right" onClick={() => cycleAgent(1)}>
              →
            </span>
          </div>
          <div className="agent-showcase">
            {agentCards.map((type) => (
              <AgentCard key={type} type={type} active={agentType === type} onClick={() => handleClick(type)} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
