import { useRef, useMemo } from 'react'
import * as THREE from 'three'
import { useFrame } from '@react-three/fiber'

export function EnergyParticles({ count = 100 }) {
  const points = useRef()

  const particleTexture = useMemo(() => {
    const canvas = document.createElement('canvas')
    canvas.width = 64
    canvas.height = 64
    const ctx = canvas.getContext('2d')

    const center = 32
    const radius = 32
    const gradient = ctx.createRadialGradient(center, center, 0, center, center, radius)
    gradient.addColorStop(0, 'rgba(0, 255, 157, 1)')
    gradient.addColorStop(0.5, 'rgba(0, 255, 157, 0.5)')
    gradient.addColorStop(1, 'rgba(0, 255, 157, 0)')

    ctx.fillStyle = gradient
    ctx.arc(center, center, radius, 0, Math.PI * 2)
    ctx.fill()

    const texture = new THREE.CanvasTexture(canvas)
    texture.needsUpdate = true
    return texture
  }, [])

  const particleData = useMemo(() => {
    const positions = new Float32Array(count * 3)
    const velocities = Array.from({ length: count }, () => ({
      x: (Math.random() - 0.5) * 0.014,
      y: (Math.random() - 0.5) * 0.014,
      z: (Math.random() - 0.5) * 0.014,
      phaseOffset: Math.random() * Math.PI * 2
    }))

    for (let i = 0; i < count; i++) {
      positions[i * 3] = (Math.random() - 0.5) * 10
      positions[i * 3 + 1] = (Math.random() - 0.5) * 10
      positions[i * 3 + 2] = (Math.random() - 0.5) * 10
    }

    return { positions, velocities }
  }, [count])

  useFrame(({ clock }) => {
    const positions = points.current.geometry.attributes.position.array
    const time = clock.getElapsedTime()

    const radius = 0.2
    const speed = 0.5
    const boundary = 5

    for (let i = 0; i < count; i++) {
      const index = i * 3

      // Update position
      positions[index] += particleData.velocities[i].x
      positions[index + 1] += particleData.velocities[i].y
      positions[index + 2] += particleData.velocities[i].z

      // Add subtle circular motion
      const phase = time * speed + particleData.velocities[i].phaseOffset
      positions[index] += Math.sin(phase) * radius * 0.01
      positions[index + 2] += Math.cos(phase) * radius * 0.01

      // Pulsating effect
      const pulse = Math.sin(time * 2 + particleData.velocities[i].phaseOffset) * 0.5 + 0.5
      points.current.material.opacity = 0.4 + pulse * 0.4

      // Boundary check and bounce
      if (Math.abs(positions[index]) > boundary) {
        positions[index] *= -0.9
        particleData.velocities[i].x *= -1
      }
      if (Math.abs(positions[index + 1]) > boundary) {
        positions[index + 1] *= -0.9
        particleData.velocities[i].y *= -1
      }
      if (Math.abs(positions[index + 2]) > boundary) {
        positions[index + 2] *= -0.9
        particleData.velocities[i].z *= -1
      }
    }

    points.current.geometry.attributes.position.needsUpdate = true
  })

  return (
    <points ref={points}>
      <bufferGeometry>
        <bufferAttribute attach="attributes-position" count={count} array={particleData.positions} itemSize={3} />
      </bufferGeometry>
      <pointsMaterial
        size={0.1}
        color="#00ff9d"
        transparent
        opacity={0.8}
        blending={THREE.AdditiveBlending}
        sizeAttenuation
        map={particleTexture}
        alphaMap={particleTexture}
        depthWrite={false}
      />
    </points>
  )
}
