import { useRef, useMemo } from 'react'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'

const gridSize = 20
const spacing = 0.2
const darkGreen = '#00804d'

export function WaveGrid() {
  const points = useRef()

  const gridData = useMemo(() => {
    const totalPoints = gridSize * gridSize
    const positions = new Float32Array(totalPoints * 3)
    const connections = []

    for (let index = 0; index < totalPoints; index++) {
      const i = Math.floor(index / gridSize)
      const j = index % gridSize

      const baseIdx = index * 3
      positions[baseIdx] = (j - gridSize / 2) * spacing * 1.5
      positions[baseIdx + 1] = -0.8 // Y-coordinate
      positions[baseIdx + 2] = (i - gridSize / 2) * spacing * 1.5 - 1.5

      // Add connections
      if (j < gridSize - 1) connections.push(index, index + 1)
      if (i < gridSize - 1) connections.push(index, index + gridSize)
    }

    return { positions, connections: new Uint16Array(connections) }
  }, [])

  useFrame((state) => {
    const positions = points.current.geometry.attributes.position.array
    const time = state.clock.getElapsedTime()

    for (let index = 0; index < gridSize * gridSize; index++) {
      const i = Math.floor(index / gridSize)
      const j = index % gridSize

      const baseIdx = index * 3
      positions[baseIdx + 1] = -0.8 + Math.sin(time + i * 0.2) * 0.02 + Math.cos(time + j * 0.2) * 0.02
    }

    points.current.geometry.attributes.position.needsUpdate = true
  })

  return (
    <group>
      <points ref={points}>
        <bufferGeometry>
          <bufferAttribute attach="attributes-position" count={gridSize * gridSize} array={gridData.positions} itemSize={3} />
        </bufferGeometry>
        <pointsMaterial size={0.02} color={darkGreen} transparent opacity={0.3} blending={THREE.NormalBlending} />
      </points>

      <lineSegments>
        <bufferGeometry>
          <bufferAttribute attach="attributes-position" count={gridSize * gridSize} array={gridData.positions} itemSize={3} />
          <bufferAttribute attach="index" array={gridData.connections} count={gridData.connections.length} />
        </bufferGeometry>
        <lineBasicMaterial color={darkGreen} transparent opacity={0.05} blending={THREE.AdditiveBlending} />
      </lineSegments>
    </group>
  )
}
