import { useRef } from 'react'
import { useStore } from './store'
import { useFrame } from '@react-three/fiber'
import { easing } from 'maath'
import { MeshTransmissionMaterial } from '@react-three/drei'
import * as THREE from 'three'
import Glow from './Glow'

export default function Selector() {
  const ref = useRef()
  const store = useStore()

  useFrame(({ viewport, camera, pointer }, delta) => {
    const { width, height } = viewport.getCurrentViewport(camera, [0, 0, 3])
    const { open } = store

    const targetPosition = [(pointer.x * width) / 2, (pointer.y * height) / 2, 3]
    const targetScale = open ? 4 : 2
    const positionDamp = open ? 0 : 0.1
    const scaleDamp = open ? 0.5 : 0.2
    const targetColor = open ? '#ffffff' : '#e0e0e0'
    const colorDamp = 0.1

    easing.damp3(ref.current.position, targetPosition, positionDamp, delta)
    easing.damp3(ref.current.scale, targetScale, scaleDamp, delta)
    easing.dampC(ref.current.material.color, targetColor, colorDamp, delta)
  })

  return (
    <>
      <mesh ref={ref}>
        <circleGeometry args={[1, 64, 64]} />
        <MeshTransmissionMaterial
          samples={4}
          resolution={1024}
          anisotropicBlur={0.5}
          thickness={0.2}
          roughness={0.25}
          opacity={0.6}
          transparent
          toneMapped
          backdropOpacity={0.6}
          background={new THREE.Color('#1a1a1a')}
        />
      </mesh>
      <Glow />
    </>
  )
}
