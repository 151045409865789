import { createRoot } from 'react-dom/client'
import './styles.css'
import App from './App'
import { Overlay } from './Overlay'
import { CursorTrail } from './CursorTrail'
import Player from './Player'

createRoot(document.getElementById('root')).render(
  <>
    <App />
    <Player url="/audio.mp3" />
    <Overlay />
    <CursorTrail />
  </>
)
