import { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export default function Shoe() {
  const ref = useRef()
  const { nodes, materials } = useGLTF('/mesh-mask-transformed.glb')

  if (!nodes || !materials) {
    return null
  }

  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    ref.current.rotation.set(Math.cos(t / 4) / 24, Math.sin(t / 3) / 12, 0.15 + Math.sin(t / 2) / 24)
    ref.current.position.y = (0.5 + Math.cos(t / 2)) / 20
  })

  return (
    <group ref={ref}>
      <mesh
        receiveShadow
        castShadow
        geometry={nodes['Curve001'].geometry}
        material={materials['Material.001']}
        rotation={[1.5, 0, 0]}
        position={[0.05, 0.25, 0.5]}
        scale={[6, 6, 6]}
      />
    </group>
  )
}

useGLTF.preload('/mesh-mask-transformed.glb')
