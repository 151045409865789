import { useStore } from './store'
import { MeshDistortMaterial } from '@react-three/drei'
import { useCallback, useRef } from 'react'
import Shoe from './Shoe'
import useDeviceType from './hooks/useDevideType'

export default function Glow({ children }) {
  const store = useStore()
  const groupRef = useRef()
  const isMobile = useDeviceType()

  const open = useCallback(() => {
    if (!isMobile) {
      store.open = true
    }
  }, [isMobile])

  const close = useCallback(() => {
    if (!isMobile) {
      store.open = false
    }
  }, [isMobile])

  return (
    <group ref={groupRef} onPointerOver={open} onPointerOut={close} onPointerDown={open} onPointerUp={close}>
      <mesh scale={[0.3, 0.3, 0.3]} position={[0, 0, -0.7]}>
        <sphereGeometry args={[1.2, 32, 32]} />
        <MeshDistortMaterial
          color="#00ff9d"
          transparent
          opacity={store.open ? 0.8 : 0.3}
          distort={0.3}
          speed={1}
          roughness={0.1}
          metalness={0.8}
          transmission={0.6}
          emissive="#00ff9d"
          emissiveIntensity={store.open ? 2 : 1}
        />
      </mesh>
      <Shoe />
    </group>
  )
}
