import { Canvas } from '@react-three/fiber'
import { Environment } from '@react-three/drei'
import { Suspense } from 'react'
import { useStore } from './store'
import { Overlay } from './Overlay'
import { WaveGrid } from './WaveGrid'
import { EnergyParticles } from './EnergyParticles'
import Selector from './Selector'

function App() {
  const store = useStore()
  return (
    <>
      <Canvas eventSource={document.getElementById('root')} eventPrefix="client" camera={{ position: [0, 0, 4], fov: 40 }}>
        <Suspense fallback={null}>
          <color attach="background" args={['#1a1a1a']} />
          <ambientLight intensity={0.7} />
          <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, -5]} castShadow />
          <Environment preset="night" background={false} blur={store.open ? 0 : 1} />
          <WaveGrid />
          <EnergyParticles count={150} />
          <Selector />
        </Suspense>
      </Canvas>
      <Overlay />
    </>
  )
}

export default App
