import { useState, useEffect } from 'react'

const useDeviceType = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1080px)')
    const handleChange = () => setIsMobile(mediaQuery.matches)

    setIsMobile(mediaQuery.matches)

    mediaQuery.addEventListener('change', handleChange)

    return () => {
      mediaQuery.removeEventListener('change', handleChange)
    }
  }, [])

  return isMobile
}

export default useDeviceType
