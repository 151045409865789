import { createRef } from 'react'
import { proxy } from 'valtio'

const store = proxy({
  open: false,
  loaded: true,
  ref: createRef()
})

export const useStore = () => store
