import { useCallback, useEffect, useRef } from 'react'

export function CursorTrail() {
  const audioRef = useRef(null)

  useEffect(() => {
    audioRef.current = new Audio('/click.wav')
  }, [])

  const playSound = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0
      audioRef.current.play()
    }
  }, [])

  useEffect(() => {
    const createTrailElement = (x, y) => {
      const trail = document.createElement('div')
      trail.className = 'cursor-trail'
      Object.assign(trail.style, {
        left: `${x}px`,
        top: `${y}px`
      })
      document.body.appendChild(trail)

      setTimeout(() => document.body.removeChild(trail), 500)
    }

    const createExplosion = (x, y) => {
      const particleCount = 12
      for (let i = 0; i < particleCount; i++) {
        const particle = document.createElement('div')
        particle.className = 'cursor-explosion'
        Object.assign(particle.style, {
          left: `${x}px`,
          top: `${y}px`
        })

        // Assign random angle for particle movement
        const angle = (i / particleCount) * 2 * Math.PI
        particle.style.setProperty('--angle', `${angle}rad`)
        document.body.appendChild(particle)

        setTimeout(() => document.body.removeChild(particle), 1000)
      }
    }

    const throttle = (callback, time) => {
      let throttleTimer
      return (...args) => {
        if (!throttleTimer) {
          callback(...args)
          throttleTimer = setTimeout(() => (throttleTimer = null), time)
        }
      }
    }

    const handleMouseMove = throttle((e) => {
      createTrailElement(e.clientX, e.clientY)
    }, 50)

    const handleClick = (e) => {
      playSound() // Ensure `playSound` is defined elsewhere
      createExplosion(e.clientX, e.clientY)
    }

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('click', handleClick)
    }
  }, [])

  return null
}
